<template>
  <div class="container">
    <navigation style="position:relative;z-index:9999;" msg="企业评论"></navigation>
    <div v-if="list.length>0">
      <van-pull-refresh success-text="刷新成功" v-model="isDownLoading" @refresh="onDownRefresh" class="list">
        <van-list v-for="(item,index) in list" :key="index">
          <div @click="goDetail(item.id)"  class="item">
            <div style="display:flex;">
              <div style="width:1rem;height:1rem;margin-right:0.15rem;">
                  <img style="width:1rem;height:1rem;border-radius:50%;" src="../../assets/user_1.jpg" alt="">
              </div>
              <div style="width:5rem;">
                <div class="title">对{{item.staff_name}}的评价</div>
                <!-- <div class="content">{{item.content}}</div> -->
                <div class="content">{{item.is_open == 2 ? '******************' : item.content}}</div>
                <div v-if="item.is_open ==2">{{item.type == 1 ? "企业已屏蔽" : "员工已屏蔽"}}</div>
              </div>

            </div>
            <van-icon style="float:right" size=".35rem" name="arrow"/>
          </div>

        </van-list>
        <div v-if="nomore == true" class="nomore">没有更多数据~</div>
      </van-pull-refresh>
    </div>
    <div class="nomsg" v-else>
      <div v-if="showLoading == false">
        <img  src="../../assets/myqy.png" alt="">
        <div>您还没有任何企业信息哦</div>
      </div>
      
    </div>
     <van-loading v-if="showLoading" size="45" class="loading" type="spinner" />
  </div>
</template>
<script>
import { Toast } from 'vant'
import navigation from "../../components/navigation/index"
export default {
  components:{
    navigation
  },
  data(){
    return {
      list:[],
      page:1,
      nomore:false,
      count:null,
      isDownLoading:false,
      showLoading:false
    }
  },
  mounted(){
    let page = this.page
    this.getList(page)
    window.onscroll = ()=>{
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
      var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if( scrollTop + windowHeight >= scrollHeight - 30){
          page++
          if(this.list.length != this.count){
              this.getList(page)
          }else{
              this.nomore = true
          }
          
      }

    }
   
  },
  methods:{
    onDownRefresh(){
       let page = this.page
      this.getList(page)
    },
    getList(page){
      let id = this.$route.query.id
      this.showLoading = true
      this.$http.post("/user/v1/Message/companyComment",{reqType:"companyComment",company_id:id,pageno:page,limit:15})
      .then((res)=>{
        res = JSON.parse(res.data)
         console.log(res)
        if(res.code == 0){
          this.showLoading = false
          this.isDownLoading = false
          this.list = this.list.concat(res.data.comment)
          this.count = res.data.count
        }else{
          Toast.fail(res.msg)
        } 
      })
      .catch((e)=>{console.log(e)})
    },
    goDetail(id){

      this.$router.push({path:"./commentDetail",query:{id:id,type:1}})
    },
  }
}
</script>
<style scoped>
.list{
  margin-top:0.2rem;
}
.item{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:0.4rem 0.4rem;
  background:#fff;
}
.title{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #777777;
}
.content{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #555555;
margin-top:0.2rem;
}
.nomore{
 text-align: center;
 color:#ddd;
 margin-top:0.3rem;
}
.nomsg{
  text-align: center;
  margin-top:2rem;
}
.nomsg img{
  width:3rem;height:2.68rem;
}
.nomsg div{
  height: 0.45rem;
font-size: 0.32rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #B6B6B6;
line-height: 0.45rem;
}
.loading{
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-0.2rem;
}
</style>